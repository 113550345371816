<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2 s-fixed-header">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.readyForInvoicing')}}
                                        <span v-if="pagination.total">({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="info" size="sm"
                                                      :title="$t('button.title.addNewItem')"
                                                      :disabled="selectedProcessLength <= 0"
                                                      @click="handlePreInvoiceAddClick"
                                                      v-if="$global.hasPermission('preinvoicesstore')" v-b-tooltip.hover>
                                                <i class="fe fe-plus"></i>
                                                {{$t('button.addNew')}} ({{selectedProcessLength}})
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('pricesview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="invoice-table">
                    <b-table hover responsive
                         :busy="listingLoading"
                         :items="dataSource"
                         :fields="columns"
                         :sort-by.sync="sortField"
                         ref="orderTable"
                         selectable
                         @row-clicked="handleRowClick"
                         @sort-changed="handleSortChange">
                        <template v-slot:cell(id)="{ detailsShowing, item, index, rowSelected}">
                            {{index + 1}}
                        </template>
                        <template v-slot:cell(created_at)="record">
                            {{ $global.dateFormat(record.item.created_at, 'MMMM') }}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{ (record.item.supplier_carrier || {}).name }}
                        </template>
                        <template v-slot:cell(object_type)="record">
                            <transport-name :type="record.item.object_type"></transport-name>
                        </template>
                        <template v-slot:cell(price)="record">
                            {{ record.item.price }}
                            {{ (record.item.currency || {}).label }}
                        </template>
                        <template v-slot:cell(status)="record">
                            <span v-if="record.item.supplier_carrier_invoiced_at" class="badge badge-success">
                                {{$t('title.invoiced')}}
                            </span>
                            <span v-else class="badge badge-danger">
                                {{$t('title.pending')}}
                            </span>
                        </template>
                    </b-table><!-- /.b-table -->
                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.invoice-table-->
                <div class="invoice-operation">
                    <a-drawer
                        placement="right"
                        :width="'86%'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationClose"
                        :visible="operation !== null && operation !== 'detail'"
                        :zIndex="10"
                        :title="$t(operationTitle)"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col sm="12" v-for="(supplier, sIndex) in selectedProcessSuppliers" :key="sIndex">
                                    <b-row>
                                        <b-col sm="12">
                                            <h3>
                                                <span class="clearfix">
                                                    <span class="pull-left">{{supplier.name}}</span>
                                                    <span class="pull-right">
                                                        {{supplier.total}} ({{supplier.currency}})
                                                    </span>
                                                </span>
                                            </h3>
                                            <b-row>
                                                <b-col sm="12" v-if="supplier.error">
                                                    <p class="text-danger">{{$t('msc.requiredFieldsMissing')}}</p>
                                                </b-col>
                                                <b-col sm="4">
                                                    <b-form-group
                                                        :label="$t('input.invoiceDate')+' *'">
                                                        <b-form-datepicker
                                                            placeholder=""
                                                            v-model="selectedProcessSuppliers[sIndex].invoice_date"
                                                            :state="((formErrors.has('invoice_date') ? false : null))"
                                                            :class="[{'invalid is-invalid': (formErrors.has('invoice_date'))}]"></b-form-datepicker>
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                            </b-row>
                                        </b-col>
                                        <b-col sm="12" v-for="(value, index) in supplier.items" :key="index">
                                            <div class="card">
                                                <div class="card-header">
                                                    <a @click="() => handleRemoveClick(sIndex, value)" class="ml-2 badge badge-danger">
                                                        <i class="fa fa-trash"></i>
                                                        {{$t('button.delete')}}
                                                    </a>
                                                    <div v-b-toggle="`collapse-${supplier.id}-${index}`">
                                                        <h5 class="m-0 p-0">
                                                            {{value.title + '#' + value.load_number}}
                                                        </h5>
                                                        <small> <strong>{{$t('title.total')}}:</strong> {{value.price}} </small>
                                                        <small class="ml-4">
                                                            <strong>
                                                                {{$t('title.currency')}}:
                                                            </strong>{{value.currency}}
                                                        </small>
                                                        <small class="ml-4">
                                                            <strong>
                                                                {{$t('title.supplierCarrier')}}:
                                                            </strong>{{(value.supplier_carrier || {}).name}}
                                                        </small>
                                                        <div>
                                                            <small v-if="value.object_type == 'inbound_load' || value.object_type == 'cc_inbound_load'">
                                                                <span v-if="value.loading_factors == value.load_items.length">
                                                                    FTL load
                                                                </span>
                                                                <span v-else>
                                                                    LTL Load
                                                                </span>
                                                            </small>
                                                            <small v-else-if="value.object_type == 'spot_inbound_load'">Single Pickup Load</small>
                                                            <small v-else>{{value.object_type}}</small>
                                                        </div>
                                                    </div>
                                                </div><!--/.card-header-->
                                                <b-collapse :id="`collapse-${supplier.id}-${index}`">
                                                    <div class="card-body">
                                                        <table class="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <td>#</td>
                                                                    <td>{{$t('title.vinNumber')}}</td>
                                                                    <td>{{$t('title.orderId')}}</td>
                                                                    <td>{{$t('title.route')}}</td>
                                                                    <td>{{$t('title.brand')}}</td>
                                                                    <td>{{$t('title.model')}}</td>
                                                                    <td>{{$t('title.versionCode')}}</td>
                                                                    <td>{{$t('title.price')}}</td>
                                                                    <td>{{$t('title.currency')}}</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(oValue, oIndex) in value.load_items">
                                                                    <td>{{oIndex + 1}}</td>
                                                                    <td>{{oValue.vin_number}}</td>
                                                                    <td>{{oValue.order_id}}</td>
                                                                    <td>{{oValue.route}}</td>
                                                                    <td>{{oValue.brand}}</td>
                                                                    <td>{{oValue.model_title}}</td>
                                                                    <td>{{oValue.model_version_code}}</td>
                                                                    <td>{{oValue.price}}</td>
                                                                    <td>{{(oValue.currency || {}).label }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div><!--/.card-body-->
                                                </b-collapse>
                                            </div><!--/.card-->
                                        </b-col>
                                    </b-row>
                                    <hr />
                                </b-col>
                            </b-row>

                            <div class="drawer-footer">
                                <b-button
                                    size="sm"
                                    type="submit"
                                    variant="primary"
                                    :disabled="global.pendingRequests > 0"
                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                >
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('button.save')}}
                                </b-button>
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form><!--/form-->
                    </a-drawer>
                </div><!--/.invoice-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker reset-button placeholder=""
                                           v-model="filters.from_date" class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12" class="mb-3">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker reset-button placeholder=""
                                           v-model="filters.to_date" class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12" class="mb-3">
                                    <b-form-group :label="$t('input.transportTypes')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.transportTypes"
                                            placeholder=""
                                            v-model="filters.object_types"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12" class="mb-3">
                                    <b-form-group
                                            :label="$t('input.carriers')">
                                        <treeselect
                                                :multiple="true"
                                                :options="dropdowns.supplierCarriers"
                                                placeholder=""
                                                v-model="filters.supplier_carriers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">

        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {
        suppliers: []
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        object_types: [],
        supplier_carriers: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: '#',
            key: 'id',
            sortable: false,
        },
        {
            label: self.$t('column.createdAt'),
            key: 'created_at',
            sortable: true,
        },
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.transport'),
            key: 'object_type',
            sortable: true,
        },
        {
            label: self.$t('column.price'),
            key: 'price',
            sortable: true,
        },
    ];

    export default {
        mixins: [ListingMixin],
        components: {Treeselect},
        data() {
            return {
                operationTitle: 'title.createPreInvoice',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: '/pre/invoices/processes',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    currencies: [],
                    transportTypes: [
                        {id: 'cc_inbound_load', label: this.$t('title.ccInboundLoad')},
                        {id: 'inbound_load', label: this.$t('title.inboundLoad')},
                        {id: 'spot_inbound_load', label: this.$t('title.singlePickupLoad')},
                        {id: 'spot_load', label: this.$t('title.spotLoad')},
                        {id: 'dealer_to_dealer_load', label: this.$t('title.dealerToDealerLoad')},
                        {id: 'reexport_load', label: this.$t('title.reexportLoad')},
                    ],
                    supplierCarriers: []
                },
                show: true,
                selectedProcessLength: 0,
                selectedProcess: [],
                selectedProcessSuppliers: [],
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('title.createPreInvoice')
            this.getSupplierCarriers()
        },
        methods: {
            handleRowClick(item, rowIndex, event) {
                const index = _.findIndex(this.selectedProcess, {id: item.id});
                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                    delete this.selectedProcess[index]
                    this.selectedProcessLength = this.selectedProcessLength - 1
                } else { // else add new
                    if(item.supplier_carrier_invoiced_at !== null) {
                        setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                        return false;
                    }

                    this.selectedProcess.push(item)
                    setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                    this.selectedProcessLength = this.selectedProcessLength + 1
                }

                this.selectedProcess = _.compact(this.selectedProcess)
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = 'title.createPreInvoice'
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                if(this.validateInput())
                    return false;

                try {
                    const response = await request({
                        url: '/pre/invoices/store',
                        method: "post",
                        data: {
                            suppliers: _.map(this.selectedProcessSuppliers, (supplier) => ({
                                id: supplier.id,
                                total: supplier.total,
                                invoice_date: this.$global.dateToUtcDate(supplier.invoice_date, 'YYYY-MM-DD', 'YYYY-MM-DD'),
                                items: _.map(supplier.items, (item) => ({
                                    ...item,
                                    supplier_carrier: [],
                                    load_items: item.load_items.map(lItem => ({
                                        id: lItem.id,
                                        vin_number: lItem.vin_number,
                                        order_id: lItem.order_id,
                                        price: lItem.price,
                                        currency: lItem.currency.id,
                                    }))
                                }))
                            }))
                        }
                    })

                    this.itemAdded()
                    this.handleOperationClose()
                } catch (e) {
                    this.handleServerError(e)
                }
            },
            async handlePreInvoiceAddClick() {
                if(this.selectedProcess.length <= 0)
                    return false;

                try {
                    const response = await request({
                        url: '/pre/invoices/processes/detail',
                        method: "post",
                        data: {
                            ids: this.selectedProcess.map(item => item.id),
                            object_ids: this.selectedProcess.map(item => item.object_guid),
                        }
                    });

                    const {data} = response
                    this.calculateInvoices(data)
                    this.setOperation('add')
                } catch (e) {
                    console.log(e)
                }
            },
            validateInput() {
                let error = false

                _.map(this.selectedProcessSuppliers, ({invoice_date}, index) => {
                    this.selectedProcessSuppliers[index].error = false

                    if(!invoice_date) {
                        this.selectedProcessSuppliers[index].error = true
                        error = true
                    }
                })

                return error;
            },
            calculateInvoices(data) {
                const items = [];
                const supplierCarriers = [];

                data.map( (item) => {
                    // if(_.includes(['inbound_load', 'cc_inbound_load', 'spot_inbound_load', 'outbound_load', 'spot_load', 'dealer_to_dealer_load'], item.object_type) && item.inbound_load) {
                    let subItems = [];
                    let route = '';
                    let loading_factors = 0;
                    let title = this.$t('title.inboundLoad');
                    if (_.includes(['inbound_load', 'cc_inbound_load', 'spot_inbound_load'], item.object_type) && item.inbound_load) {
                        subItems = item.inbound_load.inbound_load_orders;
                        route = item.inbound_load.route;
                        loading_factors = item.inbound_load.loading_factors
                    } else if (_.includes(['outbound_load'], item.object_type) && item.outbound_load) {
                        title = this.$t('title.outboundLoad');
                        subItems = item.outbound_load.outbound_load_orders;
                        route = item.outbound_load.route;
                        loading_factors = item.inbound_load.loading_factors
                    } else if (_.includes(['spot_load'], item.object_type) && item.spot_load) {
                        title = this.$t('title.spotLoad');
                        subItems = item.spot_load.spot_load_orders;
                    } else if (_.includes(['reexport_load'], item.object_type) && item.reexport_load) {
                        title = this.$t('title.reexportLoad');
                        subItems = item.reexport_load.reexport_load_orders;
                    } else if (_.includes(['dealer_to_dealer_load'], item.object_type) && item.dealer_to_dealer_load) {
                        title = this.$t('title.dealerToDealerLoad');
                        subItems = item.dealer_to_dealer_load.dealer_to_dealer_load_orders;
                    }

                    const subItem = subItems.map(i => ({
                        id: i.id,
                        vin_number: i.order.vin_number,
                        order_id: i.order.order_id,
                        order_country: i.order.order_country,
                        model_title: (i.order.model || {}).title,
                        model_version_code: (i.order.model || {}).version_code,
                        brand: (i.order.brand || {}).title,
                        price: i.price,
                        route: route,
                        currency: i.currency,
                    }))

                    items.push({
                        process_operation_id: item.id,
                        currency: (item.currency || {}).label,
                        price: item.price,
                        object_type: item.object_type,
                        object_guid: item.object_guid,
                        load_number: item.load_number,
                        loading_factors: loading_factors,
                        supplier_carrier: item.supplier_carrier,
                        load_items: subItem,
                        title: title
                    })

                    supplierCarriers.push({
                        id: item.supplier_carrier.id,
                        name: item.supplier_carrier.name,
                        total: 0,
                        items: [],
                        invoice_date: null,
                        error: null
                    })
                })

                const suppliers = _.uniqBy(supplierCarriers, 'id');
                _.map(suppliers, ({id}, index) => {
                    _.map(_.filter(items, ({supplier_carrier}) => (supplier_carrier.id == id)), (items) => {
                        suppliers[index].total = suppliers[index].total + items.price;
                        suppliers[index].currency = items.currency;
                    })

                    suppliers[index].items = _.filter(items, ({supplier_carrier}) => (supplier_carrier.id == id))
                })

                this.selectedProcessSuppliers = suppliers
            },
            handleRemoveClick(supplierIndex, value) {
                const items = _.filter(this.selectedProcessSuppliers[supplierIndex].items, (i) => i.object_guid !== value.object_guid)
                this.selectedProcessSuppliers[supplierIndex].items = items
                this.selectedProcessSuppliers[supplierIndex].total = _.sumBy(items, 'price')
            },
            hasListAccess() {
                return this.$global.hasPermission('preinvoicesview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.selectedProcess = [];
                this.selectedProcessLength = 0;
                this.selectedProcessSuppliers.length = 0;
                this.$refs.orderTable.clearSelected();
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .collapsed > .when-open,
    .not-collapsed > .when-closed {
        display: none;
    }
</style>
